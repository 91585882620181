import { createStore, useStore as baseUseStore, Store } from "vuex";
import { InjectionKey } from "vue";
import { UserState } from "../../types";

interface State {
  user: UserState;
}

export const key: InjectionKey<Store<{ user: UserState }>> = Symbol();

export const initialUserState: UserState = {
  id: "",
  firstName: "",
  lastName: "",
  academicTitle: "DR_HAB_N_MED",
  email: "",
  registerStatus: "",
  showCertificate: false,
};

export const store = createStore<State>({
  state: { user: initialUserState },
  mutations: {
    setUser(state, data: UserState) {
      state.user = data;
      sessionStorage.setItem("user", JSON.stringify(data));
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
