
import Maintenance from "@hd2/common/src/views/Maintenance.vue";
import Loading from "@hd2/common/src/views/Loading.vue";
import { defineComponent, ref, inject, watch, Ref } from "vue";
import { useI18n } from "vue-i18n";
import plPL from "ant-design-vue/es/locale/pl_PL";
import "moment/dist/locale/pl";
import HeaderComponent from "./components/Header.vue";
import moment from "moment";
import { useKeycloak } from "@baloise/vue-keycloak";

import { Profile, RegisterStatus, UserState } from "../types";
import { initialUserState, useStore } from "./store";
import { useRouter } from "vue-router";
import CertPopup from "./components/CertPopup.vue";
import Keycloak, { KeycloakInstance } from "keycloak-js";
import { AxiosStatic } from "axios";
moment.locale("pl");

export const AppComponent = defineComponent({
  components: {
    Maintenance,
    Loading,
    CertPopup,
    "app-header": HeaderComponent,
  },
  setup() {
    const maintenance = inject("maintenance");
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const authUrl = inject("authUrl");
    const store = useStore();
    const router = useRouter();

    const loading: Ref<boolean> = ref(false);
    const certPopupVisible: Ref<boolean> = ref(false);

    const showCertPopup = () => {
      if (store.state.user.showCertificate) {
        certPopupVisible.value = true;
      }
    };

    const setup = async (
      token = "fdsf3458fhdfad",
      userState: UserState = initialUserState
    ) => {
      loading.value = true;

      const registerStatus = await http
        .get(`v1/doctors/${token}/register`)
        .then((res): RegisterStatus => res.data);

      userState.id = token;
      userState.registerStatus = registerStatus;

      if (registerStatus === "FULLY_REGISTERED") {
        const profile = await http
          .get(`v1/doctors/${token}/profile`)
          .then((res): Profile => res.data);
        userState = {
          ...userState,
          ...profile,
        };
      } else if (registerStatus === "NO_ACCOUNT") {
        await http.post(`v1/doctors/${token}/register`);
        userState.registerStatus = "PERSONAL";
      }

      store.commit("setUser", userState);
      router.replace(location.href);
      loading.value = false;

      showCertPopup();
    };

    const logout = () => {
      const { keycloak } = useKeycloak();
      keycloak.logout();
    };

    const onTokenRefresh = (keycloak: KeycloakInstance) => {
      keycloak.onTokenExpired = () => {
        onTokenRefresh(keycloak);
      };
      keycloak
        .updateToken(10)
        .then(async () => {
          http.defaults.headers.Authorization = `Bearer ${keycloak.token}`;
        })
        .catch(() => {
          keycloak.logout();
        });
    };

    if (!maintenance) {
      if (authUrl) {
        const { isAuthenticated, keycloak } = useKeycloak();
        keycloak.onTokenExpired = () => {
          onTokenRefresh(keycloak);
        };
        watch(isAuthenticated, async (changed) => {
          if (changed) {
            http.defaults.headers.Authorization = `Bearer ${keycloak.token}`;
            setup(keycloak.tokenParsed?.sub, {
              ...initialUserState,
              firstName: (
                keycloak.tokenParsed as Keycloak.KeycloakTokenParsed & {
                  given_name: string;
                }
              )["given_name"],
              lastName: (
                keycloak.tokenParsed as Keycloak.KeycloakTokenParsed & {
                  family_name: string;
                }
              )["family_name"],
              email: (
                keycloak.tokenParsed as Keycloak.KeycloakTokenParsed & {
                  email: string;
                }
              )["email"],
            });
          }
        });
      } else {
        setup();
      }
    }

    return {
      maintenance: ref(maintenance),
      loading,
      t,
      certPopupVisible,
      plPL,
      logout,
    };
  },
});
export default AppComponent;
