
import { defineComponent, h } from "vue";
import { Loading3QuartersOutlined } from "@ant-design/icons-vue";

export const LoadingComponent = defineComponent({
  emits: ["logout"],
  props: {
    logoutText: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const indicator = h(Loading3QuartersOutlined, {
      style: {
        fontSize: "min(25vw, 250px)",
      },
      spin: true,
    });
    const logout = () => {
      emit("logout");
    };
    return {
      indicator,
      logout,
    };
  },
});

export default LoadingComponent;
