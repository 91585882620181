import { Availability, AvailabilityStatus } from "../../../types";
import moment from "moment";

export const callAvailabilities: Availability = {
  "2021-08-16": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  "2021-08-17": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  "2021-08-18": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  "2021-08-19": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  "2021-08-20": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  "2021-08-21": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  "2021-08-22": {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
};

export const houseAvailabilities: Availability = {
  [`${moment().startOf("week").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "PLANNED" as AvailabilityStatus,
    "09:00": "PLANNED" as AvailabilityStatus,
    "10:00": "PLANNED" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  [`${moment().startOf("week").add(1, "days").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "ACCEPTED" as AvailabilityStatus,
    "11:00": "ACCEPTED" as AvailabilityStatus,
    "12:00": "ACCEPTED" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  [`${moment().startOf("week").add(2, "days").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "REJECTED" as AvailabilityStatus,
    "16:00": "REJECTED" as AvailabilityStatus,
    "17:00": "REJECTED" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  [`${moment().startOf("week").add(3, "days").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  [`${moment().startOf("week").add(4, "days").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  [`${moment().startOf("week").add(5, "days").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "NONE" as AvailabilityStatus,
    "09:00": "NONE" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
  [`${moment().startOf("week").add(6, "days").format("YYYY-MM-DD")}`]: {
    "00:00": "NONE" as AvailabilityStatus,
    "01:00": "NONE" as AvailabilityStatus,
    "02:00": "NONE" as AvailabilityStatus,
    "03:00": "NONE" as AvailabilityStatus,
    "04:00": "NONE" as AvailabilityStatus,
    "05:00": "NONE" as AvailabilityStatus,
    "06:00": "NONE" as AvailabilityStatus,
    "07:00": "NONE" as AvailabilityStatus,
    "08:00": "PLANNED" as AvailabilityStatus,
    "09:00": "PLANNED" as AvailabilityStatus,
    "10:00": "NONE" as AvailabilityStatus,
    "11:00": "NONE" as AvailabilityStatus,
    "12:00": "NONE" as AvailabilityStatus,
    "13:00": "NONE" as AvailabilityStatus,
    "14:00": "NONE" as AvailabilityStatus,
    "15:00": "NONE" as AvailabilityStatus,
    "16:00": "NONE" as AvailabilityStatus,
    "17:00": "NONE" as AvailabilityStatus,
    "18:00": "NONE" as AvailabilityStatus,
    "19:00": "NONE" as AvailabilityStatus,
    "20:00": "NONE" as AvailabilityStatus,
    "21:00": "NONE" as AvailabilityStatus,
    "22:00": "NONE" as AvailabilityStatus,
    "23:00": "NONE" as AvailabilityStatus,
  },
};
